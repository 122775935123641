import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    publicPools: [], // list of public pools
    ongoingPools: [], // list of ongoing pools
    historicPools: [], // list of historic pools
  },
  getters: {
    getAllPublicPools(state) {
      return state.publicPools
    },
    getAllOngoingPools(state) {
      return state.ongoingPools
    },
    getAllHistoricPools(state) {
      return state.historicPools
    },
  },
  mutations: {
    SET_PUBLIC_POOLS(state, publicPools) {
      state.publicPools = publicPools
    },
    SET_ONGOING_POOLS(state, ongoingPools) {
      state.ongoingPools = ongoingPools
    },
    SET_HISTORIC_POOLS(state, historicPools) {
      state.historicPools = historicPools
    },
  },
  actions: {
    fetchAllPublicPools({ commit }, args) {
      useJwt.getPublicPools(args)
        .then(result => commit('SET_PUBLIC_POOLS', result.data.data))
    },
    fetchAllOngoingPools({ commit }, args) {
      useJwt.getCurrentPools(args)
        .then(result => commit('SET_ONGOING_POOLS', result.data.data))
    },
    fetchAllHistoricPools({ commit }, args) {
      useJwt.getHistoricPools(args)
        .then(result => commit('SET_HISTORIC_POOLS', result.data.data))
    },
  },
}
