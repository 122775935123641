import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('@/views/Index.vue'),
      meta: {
        layout: 'horizontal',
        pageTitle: 'Survivor Pool',
      },
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        authRequired: true,
        layout: 'horizontal',
        pageTitle: 'Home - Survivor Pool',
      },
    },
    {
      path: '/history',
      name: 'history',
      component: () => import('@/views/History.vue'),
      meta: {
        authRequired: true,
        layout: 'horizontal',
        pageTitle: 'History - Survivor Pool',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      meta: {
        authRequired: true,
        layout: 'horizontal',
        pageTitle: 'Profile - Survivor Pool',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        pageTitle: 'Register - Survivor Pool',
      },
    },
    {
      path: '/verification',
      name: 'verify',
      component: () => import('@/views/Verify.vue'),
      meta: {
        pageTitle: 'Verify Email - Survivor Pool',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        pageTitle: 'Login - Survivor Pool',
      },
    },
    {
      path: '/thank-you',
      name: 'thank-you',
      component: () => import('@/views/Login.vue'),
      meta: {
        pageTitle: 'Thank you! Login - Survivor Pool',
      },
    },
    {
      path: '/recover-password',
      name: 'recover-password',
      component: () => import('@/views/PasswordRecovery.vue'),
      meta: {
        pageTitle: 'Password Recovery - Survivor Pool',
      },
    },
    {
      path: '/data',
      name: 'data',
      component: () => import('@/views/static/Data.vue'),
      meta: {
        layout: 'horizontal',
        pageTitle: 'Data - Survivor Pool',
      },
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/views/static/Privacy.vue'),
      meta: {
        layout: 'horizontal',
        pageTitle: 'Privacy Policy - Survivor Pool',
      },
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('@/views/static/Terms.vue'),
      meta: {
        layout: 'horizontal',
        pageTitle: 'Terms and Conditions - Survivor Pool',
      },
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/static/Contact.vue'),
      meta: {
        layout: 'horizontal',
        pageTitle: 'Contact Us - Survivor Pool',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Page Not Found - Survivor Pool',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

export default router
