import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import misc from './misc'
import pools from './pools'
import questions from './questions'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,

    misc,
    pools,
    questions,
  },
  strict: process.env.DEV,
})
