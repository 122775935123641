import apiJwtConfig from './jwtConfig'

export default class JwtService {
    // Will be used by this service for making API calls
    axiosIns = null

    // jwtConfig <= Will be used by this service
    jwtConfig = { ...apiJwtConfig }

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // For Refreshing Token
    subscribers = []

    constructor(axiosIns) {
      this.axiosIns = axiosIns

      // Request Interceptor
      this.axiosIns.interceptors.request.use(
        config => {
          // Get token from localStorage
          const accessToken = this.getToken()

          // If token is present add it to request's Authorization Header
          if (accessToken) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          }

          // set base url
          config.baseURL = this.jwtConfig.baseUrl // eslint-disable-line no-param-reassign

          return config
        },
        error => Promise.reject(error),
      )

      // Add request/response interceptor
      this.axiosIns.interceptors.response.use(
        response => response,
        error => {
          /* const { config, response: { status } } = error */
          const { response } = error
          /* const originalRequest = config */

          // if (status === 401) {
          if (response && response.status === 401) {
            console.log('useJwt 401 Unauthorized', response)

            // Clear localstorage
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem('userData')

            // Redirect to login page
            window.location.href = '/login'
            window.location.reload()
          }
          return Promise.reject(error)
        },
      )
    }

    onAccessTokenFetched(accessToken) {
      this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
      this.subscribers.push(callback)
    }

    getToken() {
      return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    setToken(value) {
      localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    /* AUTHENTICATION */

    login(...args) {
      return this.axiosIns.post(`${this.jwtConfig.endpoints.login}`, ...args)
    }

    verify(code) {
      return this.axiosIns.post(`${this.jwtConfig.endpoints.verify}/${code}`)
    }

    register(...args) {
      return this.axiosIns.post(`${this.jwtConfig.endpoints.register}`, ...args)
    }

    recoverPasswordMobile(...args) {
      return this.axiosIns.post(`${this.jwtConfig.endpoints.users}/passwordreset/start/mobile`, ...args)
    }

    recoverPasswordEmail(...args) {
      return this.axiosIns.post(`${this.jwtConfig.endpoints.users}/passwordreset/start/email`, ...args)
    }

    resetPassword(recoveryCode, ...args) {
      return this.axiosIns.post(`${this.jwtConfig.endpoints.users}/passwordreset/finish/${recoveryCode}`, ...args)
    }

    /* USERS */

    getCurrentUser() {
      return this.axiosIns.get(`${this.jwtConfig.endpoints.users}/me`)
    }

    updateUser(...args) {
      return this.axiosIns.put(`${this.jwtConfig.endpoints.users}/me`, ...args)
    }

    updatePassword(...args) {
      return this.axiosIns.put(`${this.jwtConfig.endpoints.users}/me/password`, ...args)
    }

    getNotifSettings() {
      return this.axiosIns.get(`${this.jwtConfig.endpoints.users}/me/notification/settings`)
    }

    setNotifSettings(...args) {
      return this.axiosIns.put(`${this.jwtConfig.endpoints.users}/me/notification/settings`, ...args)
    }

    /* PUBLIC */

    getPublicCurrentQuestion() {
      return this.axiosIns.get(`${this.jwtConfig.endpoints.questions}/current/public`)
    }

    getPublicPools() {
      return this.axiosIns.get(`${this.jwtConfig.endpoints.cohorts}/current`)
    }

    getPromotionalCopy() {
      return this.axiosIns.get(`${this.jwtConfig.endpoints.promotionalCopy}`)
    }

    /* Logged In */
    // Questions
    getCurrentQuestion(...args) {
      return this.axiosIns.get(`${this.jwtConfig.endpoints.questions}/current`, ...args)
    }

    postCurrentQuestionAnswer(choice, ...args) {
      return this.axiosIns.post(`${this.jwtConfig.endpoints.questions}/current/${choice}`, ...args)
    }

    // Cohorts
    getCurrentPools() {
      return this.axiosIns.get(`${this.jwtConfig.endpoints.users}/me/cohorts`)
    }

    getHistoricPools() {
      return this.axiosIns.get(`${this.jwtConfig.endpoints.users}/me/cohorts/historic`)
    }

    // Notifications
    getLastResponse() {
      return this.axiosIns.get(`${this.jwtConfig.endpoints.users}/me/laststatus`)
    }

    getUnseenNotifs() {
      return this.axiosIns.get(`${this.jwtConfig.endpoints.users}/me/notifications`)
    }
}
