import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    publicQuestion: {}, // current question (public)
    currentQuestion: {}, // current question
    lastResponse: {}, // last response by user
    answerResponse: null, // response upon answering the question
  },
  getters: {
    getPublicQuestion(state) {
      return state.publicQuestion
    },
    getCurrentQuestion(state) {
      return state.currentQuestion
    },
    getLastResponse(state) {
      return state.lastResponse
    },
    getAnswerResponse(state) {
      return state.answerResponse
    },
  },
  mutations: {
    SET_PUBLIC_QUESTION(state, publicQuestion) {
      state.publicQuestion = publicQuestion
    },
    SET_CURRENT_QUESTION(state, currentQuestion) {
      state.currentQuestion = currentQuestion
    },
    SET_LAST_RESPONSE(state, lastResponse) {
      state.lastResponse = lastResponse
    },
    SET_ANSWER_RESPONSE(state, answerResponse) {
      state.answerResponse = answerResponse
    },
  },
  actions: {
    fetchPublicQuestion({ commit }, args) {
      useJwt.getPublicCurrentQuestion(args)
        .then(result => commit('SET_PUBLIC_QUESTION', result.data.data))
        .catch(error => {
          const errorResponse = {
            ...error.response.data,
            text: error.response.data.error,
          }
          commit('SET_PUBLIC_QUESTION', errorResponse)
        })
    },
    fetchCurrentQuestion({ commit }, args) {
      useJwt.getCurrentQuestion(args)
        .then(result => commit('SET_CURRENT_QUESTION', result.data.data))
        .catch(error => {
          const errorResponse = {
            ...error.response.data,
            text: error.response.data.error,
          }
          commit('SET_CURRENT_QUESTION', errorResponse)
        })
    },
    fetchLastResponse({ commit }, args) {
      useJwt.getLastResponse(args)
        .then(result => commit('SET_LAST_RESPONSE', result.data.data))
        .catch(error => {
          const errorResponse = {
            ...error.response.data,
            text: error.response.data.error,
          }
          commit('SET_LAST_RESPONSE', errorResponse)
        })
    },
    setCurrentQuestionAnswer({ commit }, args) {
      return new Promise((resolve, reject) => {
        useJwt.postCurrentQuestionAnswer(args)
          .then(result => {
            commit('SET_ANSWER_RESPONSE', result.data)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    resetQuestionData({ commit }) {
      commit('SET_PUBLIC_QUESTION', {})
      commit('SET_CURRENT_QUESTION', {})
      commit('SET_LAST_RESPONSE', {})
      commit('SET_ANSWER_RESPONSE', null)
    },
  },
}
