import { $themeConfig } from '@themeConfig'

export default {
  // api base url
  baseUrl: $themeConfig.app.apiBaseUrl,

  // api endpoints
  endpoints: {
    login: '/api/users/login',
    register: '/api/users/register',
    verify: '/api/users/email/verification',
    logout: '/api/logout',
    users: '/api/users',
    questions: '/api/questions',
    cohorts: '/api/cohorts',
    promotionalCopy: '/api/promotionalcopy',
  },

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'token',
}
