import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    promotionalCopy: null, // promotional text
    unseenNotifs: {}, // unseen notifications
  },
  getters: {
    getPromotionalCopy(state) {
      return state.promotionalCopy
    },
    getUnseenNotifs(state) {
      return state.unseenNotifs
    },
  },
  mutations: {
    SET_PROMOTIONAL_COPY(state, promotionalCopy) {
      state.promotionalCopy = promotionalCopy
    },
    SET_UNSEEN_NOTIFS(state, unseenNotifs) {
      state.unseenNotifs = unseenNotifs
    },
  },
  actions: {
    fetchPromotionalCopy({ commit }, args) {
      useJwt.getPromotionalCopy(args)
        .then(result => commit('SET_PROMOTIONAL_COPY', result.data.data))
    },
    fetchUnseenNotifs({ commit }, args) {
      useJwt.getUnseenNotifs(args)
        .then(result => commit('SET_UNSEEN_NOTIFS', result.data.data))
    },
  },
}
